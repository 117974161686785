<template>
    <div>
        <vxe-table row-key row-id="id" ref="xTable1" :data="getinvoiceData">
            <!-- <vxe-table-column title="开票方名称" field="salerName"></vxe-table-column>
            <vxe-table-column title="开票方税号" field="salerTaxNo"></vxe-table-column> -->
            <vxe-table-column title="销货方名称" field="sallerName"></vxe-table-column>
            <vxe-table-column title="销货方纳税人识别号" field="sallerTaxNo"></vxe-table-column>
            <vxe-table-column title="购货方名称" field="buyerName"></vxe-table-column>
            <vxe-table-column title="购货方纳税人识别号" field="buyerTaxNo"></vxe-table-column>
            <vxe-table-column title="发票项目名称" field="invoiceItem"></vxe-table-column>
            <vxe-table-column title="发票代码" field="invoiceCode"></vxe-table-column>
            <vxe-table-column title="发票号码" field="invoiceNo"></vxe-table-column>
            <vxe-table-column title="价税合计(元)" field="totalAmount"></vxe-table-column>
            <vxe-table-column title="不含税总金额(元)" field="amount"></vxe-table-column>
            <vxe-table-column title="总税额" field="taxAmount"></vxe-table-column>
            <vxe-table-column title="税率（%）" field="taxRate"></vxe-table-column>
            <vxe-table-column title="发票流水号" field="serialNo"></vxe-table-column>
            <vxe-table-column title="发票日期" field="invoiceDate"></vxe-table-column>
        </vxe-table>
        <div slot="footer" class="dialog-footer">
            <el-button type="danger" @click="closeForm" size="small" icon="el-icon-close">关闭</el-button>
        </div>
    </div>
</template>
<script>
import request from '@/found/utils/request';

export default {
  name: 'invoice_detail',
  props: {
    formConfig: Object,
  },
  data() {
    return {
      getinvoiceData: [],
    };
  },
  components: {},
  created() {
    this.getInvoice();
  },
  methods: {
    getInvoice() {
      request
        .post('/tpm/tpmscaninvoice/listByAct', { objectId: this.formConfig.objectId })
        .then((res) => {
          if (res.success) {
            this.getinvoiceData = res.result;
          }
        });
    },
    closeForm() {
      this.$emit('onClose');
    },
  },
};
</script>
